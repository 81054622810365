const requiredStyle = ({
    backgroundImage: 'url("/warning.svg")',
    backgroundSize: "16px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "red",
});

const nonRequiredStyle = ({
    backgroundImage: "none",
    backgroundSize: "none",
    backgroundRepeat: "none",
    backgroundPosition: "none",
    backgroundColor: "white"
});

export { nonRequiredStyle, requiredStyle };