import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { v4 as uuid } from 'uuid';

import EditableTable from '../../components/table/EditableTable';
import { getColumnDefs } from "./constants/usersColumns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  createUser,
  deleteUser,
  fetchRegulations,
  fetchUsers,
  sendNotification,
  updateUser, validateFtp
} from "../../redux/actions";

const Users = () => {
  const dispatch = useDispatch();
  const regulations = useSelector(state => state.config.regulations, shallowEqual);
  const [data, setData] = useState(null);
  const gridAPI = useRef(null);

  const onNew = () => {
    setData(data => [...data, { id: uuid(), new: true, ftp: {} }]);
  }

  const onSave = async (row) => {
    if (!row.username) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Username is required' }));
    if (row.new && !row.password) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Password is required' }));

    const { payload } = await dispatch(row.new ? createUser(row) : updateUser(row));
    setData(data => data.map(u => u.id === row.id ? payload : u));
  }

  const onDelete = async (row) => {
    if (!row.new) {
      await dispatch(deleteUser(row));
    }
    setData(data => data.filter(u => u.id !== row.id));
  }

  const onFtpCheck = async (row) => {
    if (row.new) {
      return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'User needs to be saved before being able to check the FTP settings' }));
    }
    await dispatch(validateFtp(row.id));
  }

  const regulationOptions = useMemo(() => regulations.map(r => ({ label: r.label, value: r.id })), [regulations]);
  const columnDefs = useMemo(() => getColumnDefs({ regulationOptions, onSave, onDelete, onFtpCheck }), [regulationOptions]);

  const fetchData = () => {
    gridAPI.current && gridAPI.current.showLoadingOverlay();
    gridAPI.current && gridAPI.current.paginationGoToFirstPage();
    Promise.all([dispatch(fetchUsers()), dispatch(fetchRegulations())])
      .then(([{ payload }]) => setData(payload))
      .finally(() => gridAPI.current && gridAPI.current.hideOverlay());
  }

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <Button type="primary" onClick={fetchData}>Refresh Table</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onNew}>Add User</Button>
        </Col>
      </Row>
      <EditableTable
        rowData={data}
        columnDefs={columnDefs}
        defaultColDefs={{
          editable: true,
          filter: true,
          floatingFilter: true
        }}
        setAPI={(api) => gridAPI.current = api}
      />
    </div>
  );
}

export default Users;
