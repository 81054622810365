import moment from 'moment';
import * as types from "../actions/types";

const getPreviousWorkday = () => {
  let workday = moment();
  let day = workday.day();
  let diff = 1;
  if (day === 0 || day === 1) {
    diff = day + 2;
  }
  return workday.subtract(diff, 'days');
};

const config = (
  state = { regulations: [], version: null, tables: [], enums: [], authorities: [], range: [getPreviousWorkday(), getPreviousWorkday()] },
  action
) => {
  switch (action.type) {
    case types.FETCH_REGULATIONS:
    case types.UPDATE_REGULATIONS: {
      return {
        ...state,
        regulations: action.payload
      };
    }
    case types.FETCH_CONFIG: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types.FETCH_ENUMS:
    case types.ADD_ENUM:
    case types.DELETE_ENUM:
    case types.DELETE_ENUM_VALUE:
    case types.UPDATE_ENUM_VALUE: {
      return {
        ...state,
        enums: action.payload
      }
    }
    case types.FETCH_TABLES:
    case types.ADD_TABLE:
    case types.SAVE_TABLE:
    case types.UPDATE_TABLE:
    case types.DELETE_TABLE: {
      return {
        ...state,
        tables: action.payload
      }
    }
    case types.FETCH_AUTHORITIES:
    case types.UPDATE_AUTHORITIES: {
      return  {
        ...state,
        authorities: action.payload
      }
    }
    case types.UPDATE_RANGE: {
      return  {
        ...state,
        range: action.payload
      }
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { config };
