import React, { Component } from 'react';

export default class SingleSelectRenderer extends Component {
  state = {
    options: this.props.options,
    value: this.props.value,
  }

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value })
    }
    return true;
  }

  renderValue = () => {
    const { options, value } = this.state;
    if (!value) return null;
    if (!options) return value;
    const selectedValue = options.find((option) => option.value === value);
    if (!selectedValue) return 'Undefined';
    return selectedValue.label;
  }

  render() {
    return (
      <div>{this.renderValue()}</div>
    )
  }
}