import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

export default class MultiSelectRenderer extends Component {
  state = {
    value: this.props.value,
  };

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value })
    }
    return true;
  }

  render() {
    if (_.isEmpty(this.state.value)) return null;
    return (
      <div>{moment(this.state.value).format('DD/MM/YYYY')}</div>
    )
  }
}
