import { Button, DatePicker, Form } from 'antd';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { updateRange } from "../redux/actions";

const { RangePicker } = DatePicker;

const FiltersForm = ({ loading, onFinish }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const range = useSelector((state) => state.config.range, shallowEqual);
  //  On form submit, load this function
  const submitForm = async (values) => {
    await dispatch(updateRange(values.range));
    //  Call onFinish callback if exists
    if (onFinish) onFinish(values);
  }

return (
  <Form
    layout="inline"
    form={form}
    initialValues={{ range }}
    value={{ range }}
    onFinish={submitForm}
>
    <Form.Item name="range" rules={[{ required: true, message: "Required" }]}>
        <RangePicker format="DD/MM/YYYY" className="center-rangepicker" allowClear={false} />
    </Form.Item>
    <Button type="primary" htmlType="submit" disabled={loading}>
        Submit
    </Button>
  </Form>
)
}

export default FiltersForm;