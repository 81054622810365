import { Button, Modal } from 'antd';
import ArchiveTable from '../table/ArchiveTable';

const ArchiveTableModal = ({ modalData, modalVisible, setModalVisible }) => {
    return (
      <Modal
        title={modalData?.title || "Title"}
        visible={modalVisible}
        width={1200}
        onCancel={() => setModalVisible(false)}
        footer={<Button type="primary" onClick={() => setModalVisible(false)}>Close</Button>}
        bodyStyle={{ padding: 0 }}
        >
          <ArchiveTable
            rowData={modalData?.data || []}
            columnDefs={[]}
            defaultColDefs={{
            resizable: true,
            filter: true,
            floatingFilter: true
          }}
          />
      </Modal>
    )
}

export default ArchiveTableModal;