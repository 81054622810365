import * as types from "./types";

export const sendNotification = (payload) => ({
  type: types.SEND_NOTIFICATION,
  payload,
});

export const redirect = (url) => ({
  type: types.REDIRECT,
  payload: url,
});
