import { nonRequiredStyle, requiredStyle } from '../../../others';

const columnDefs = [
  {
    headerName: "Label",
    field: "label",
    editable: true,
    checkboxSelection: true,
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
];

export { columnDefs };
