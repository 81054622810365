import * as types from "./types";
import _ from 'lodash';

export const fetchUsers = () => {
  return (api) => ({
    type: types.FETCH_USERS,
    payload: api.get("v1/user"),
  });
};

export const createUser = (user) => {
  if (!user.regulation) user.regulation = [];
  return (api) => ({
    type: types.CREATE_USER,
    payload: api.post("v1/user", user),
    success: {
      message: "Create user",
      description: "Successfully created user",
    }
  });
}

export const updateUser = (user) => {
  if (!user.regulation) user.regulation = [];
  return (api) => ({
    type: types.UPDATE_USER,
    payload: api.put("v1/user", { id: user.id, data: _.omit(user, 'id') }),
    success: {
      message: "Update user",
      description: "Successfully updated user",
    }
  });
}

export const deleteUser = (user) => {
  return (api) => ({
    type: types.DELETE_USER,
    payload: api.delete(`v1/user/${user.id}`),
    success: {
      message: "Delete user",
      description: "Successfully deleted user",
    }
  });
}

export const fetchRegulations = () => {
  return (api) => ({
    type: types.FETCH_REGULATIONS,
    payload: api.get(`v1/regulation`),
  });
}

export const updateRegulations = (regulations) => {
  return (api) => ({
    type: types.UPDATE_REGULATIONS,
    payload: api.put(`v1/regulation`, regulations),
    success: {
      message: "Save regulations",
      description: "Successfully saved regulations",
    }
  });
}

export const fetchAuthorities = () => {
  return (api) => ({
    type: types.FETCH_AUTHORITIES,
    payload: api.get(`v1/authorities`),
  });
}

export const updateAuthorities = (authorities) => {
  return (api) => ({
    type: types.UPDATE_AUTHORITIES,
    payload: api.put(`v1/authorities`, authorities),
    success: {
      message: "Save competent authorities",
      description: "Successfully saved competent authorities",
    }
  });
}

export const fetchCharts = () => {
  return (api) => ({
    type: types.FETCH_CHARTS,
    payload: api.get(`v1/chart`),
  });
}

export const updateCharts = (charts) => {
  return (api) => ({
    type: types.UPDATE_CHARTS,
    payload: api.put(`v1/chart`, charts),
    success: {
      message: "Save charts",
      description: "Successfully saved charts",
    }
  });
}

export const validateFtp = (id) => {
  return (api) => ({
    type: types.VALIDATE_FTP,
    payload: api.post(`v1/user/validate-ftp`, { id }),
    success: {
      message: "Validate FTP",
      description: "Successfully validated FTP",
    }
  });
}

export const fetchTables = () => {
  return (api) => ({
    type: types.FETCH_TABLES,
    payload: api.get("v1/table"),
  });
}

export const addTable = ({ name, label, columns }) => {
  return (api) => ({
    type: types.ADD_TABLE,
    payload: api.post("v1/table", { name, label, columns }),
    success: {
      message: "Table Added",
      description: "Successfully added",
    }
  });
}

//  Action Type can be changes or removes
export const saveTable = (tableName, data, actionType) => {
  if (actionType === "changes") {
    const values = Object.values(data);
    return (api) => ({
      type: types.SAVE_TABLE,
      payload: api.put('v1/table/columns', { name: tableName, columns: values }),
      success: {
        message: "Table changes submitted",
        description: "Successfully updated",
      }
    });
  }

  if (actionType === "removes") {
    return (api) => ({
      type: types.SAVE_TABLE,
      payload: api.delete('v1/table/columns', { tableName, columns: data }),
      success: {
        message: "Table removes submitted",
        description: "Successfully updated",
      }
    });
  }
};

export const changeTableLabel = (name, label) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/label", { name, label }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table label",
    }
  });
};

export const changeTableOrder = (name, order) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/order", { name, order }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table order",
    }
  });
};

export const changeTableDescription = (name, description) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/description", { name, description }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table description",
    }
  });
};

export const changeTableThresholdsEnabled = (name, thresholdsEnabled) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/thresholdsEnabled", { name, thresholdsEnabled }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table read only",
    }
  });
}

export const changeTableReadOnly = (name, readonly) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/readonly", { name, readonly }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table read only",
    }
  });
}

export const changeTableEnableFiltering = (name, enableFiltering) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/enableFiltering", { name, enableFiltering }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table filtering",
    }
  });
}

export const changeTableRowQuery = (name, rowQuery) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/rowQuery", { name, rowQuery }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table row query",
    }
  });
}



export const changeTableRegulations = (name, regulations) => {
  return (api) => ({
    type: types.UPDATE_TABLE,
    payload: api.put("v1/table/regulations", { name, regulations }),
    success: {
      message: "Table Updated",
      description: "Successfully updated table regulations",
    }
  });
}

export const deleteTable = (name) => {
  return (api) => ({
    type: types.DELETE_TABLE,
    payload: api.delete("v1/table", { name }),
    success: {
      message: "Table deleted",
      description: "Successfully deleted",
    }
  });
};

export const fetchEnums = () => {
  return (api) => ({
    type: types.FETCH_ENUMS,
    payload: api.get("v1/enum"),
  });
}

export const addEnum = (name) => {
  return (api) => ({
    type: types.ADD_ENUM,
    payload: api.post("v1/enum", { name, values: [] }),
    success: {
      message: "Enum added",
      description: "Successfully added",
    }
  });
};

export const updateEnumValue = (name, value) => {
  return (api) => ({
    type: types.UPDATE_ENUM_VALUE,
    payload: api.put("v1/enum/value", { name, value }),
    success: {
      message: "Enum updated",
      description: "Successfully updated",
    }
  });
};

export const deleteEnumValue = (name, valueId) => {
  return (api) => ({
    type: types.DELETE_ENUM_VALUE,
    payload: api.delete(`v1/enum/${name}/value/${valueId}`),
    success: {
      message: "Enum value deleted",
      description: "Successfully deleted",
    }
  });
};

export const deleteEnum = (name) => {
  return (api) => ({
    type: types.DELETE_ENUM,
    payload: api.delete(`v1/enum/${name}`),
    success: {
      message: "Enum deleted",
      description: "Successfully deleted",
    }
  });
};
