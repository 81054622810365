import React, { useEffect, useRef, useState } from 'react';
import { AllCommunityModules } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import _ from "lodash";
import { Button } from 'antd';

//  Custom Renderers
import ActionsRenderer from './renderers/ActionsRenderer';
import ArrayOfObjectsRenderer from './renderers/ArrayOfObjectsRenderer';
import BooleanRenderer from './renderers/BooleanRenderer';

const ArchiveTable = (props) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const {
    defaultColDefs,
    onGridReady,
    onRowDoubleClicked,
    rowData,
    rowSelection = "multiple",
    gridOptions = {},
    getRowNodeId,
    property,
    tableOperations
  } = props;
  const gridAPI = useRef(null);

  useEffect(() => {
    const row = rowData[0];
    if (_.isEmpty(row)) {
      setColumnDefs([]);
    } else {
      setColumnDefs(Object.keys(row).map((k) => ({ headerName: k, field: k })));
    }
  }, [rowData]);

  function autoSizeAll(params, skipHeader) {
    const allColumnIds = params.columnApi.getAllColumns().map(column => column.getColId());
    setTimeout(() => params.columnApi.autoSizeColumns(allColumnIds, skipHeader), 10);
  }

  const onCellValueChanged = (params) => {
    tableOperations.onDataChanged(rowData, property);
    autoSizeAll(params, false);
  }

  const exportCSV = () => {
    const columnKeys = columnDefs.filter(c => !['saved', 'actions'].includes(c.field) && !c.hide).map(c => c.field);
    gridAPI.current.exportDataAsCsv({ columnKeys });
  }

  return (
    <div>
      <div style={{ marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 12 }}>
        <Button type="primary" onClick={exportCSV} style={{ marginBottom: 12 }}>Export CSV</Button>
        <div id="agGrid" className="ag-theme-balham" style={{ flex: 1, height: 600 }}>
          <AgGridReact
            suppressAnimationFrame={true}
            defaultColDef={defaultColDefs}
            columnDefs={columnDefs}
            rowData={rowData}
            modules={AllCommunityModules}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            multiSortKey={'ctrl'}
            rowSelection={rowSelection}
            suppressRowClickSelection={true}
            pagination={true}
            paginationPageSize={99999999}
            rowBuffer={50}
            // paginationAutoPageSize={true}
            getRowNodeId={getRowNodeId || ((data) => data._id)}
            frameworkComponents={{
              actionsRenderer: ActionsRenderer,
              arrayOfObjectsRenderer: ArrayOfObjectsRenderer,
              booleanRenderer: BooleanRenderer,
            }}
            onGridReady={(params) => {
              gridAPI.current = params.api;
              if (onGridReady) onGridReady(params)
            }}
            onFirstDataRendered={(params) => autoSizeAll(params, false)}
            onGridColumnsChanged={(params) => autoSizeAll(params, false)}
            onRowDoubleClicked={onRowDoubleClicked}
            onSelectionChanged={gridOptions.onSelectionChanged}
            onRowDataChanged={gridOptions.onRowDataChanged}
            onFilterChanged={gridOptions.onFilterChanged}
            onCellValueChanged={onCellValueChanged}
          />
        </div>
      </div>
      
    </div>
  )
}

export default ArchiveTable;
