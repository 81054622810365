import _ from 'lodash';
import {
  ADD_TABLE,
  ADD_ENUM,
  DELETE_TABLE,
  DELETE_ENUM,
  UPDATE_ENUM_VALUE,
  UPDATE_TABLE,
  DELETE_ENUM_VALUE
} from '../actions/types';

const INIT_STATE = {
  tables: [],
  enums: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_TABLE:
    case ADD_TABLE: {
      return {
        ...state,
        tables: _.unionBy([action.payload], state.tables, 'name'),
      };
    }

    case ADD_ENUM: {
      return {
        ...state,
        enums: [...state.enums, action.payload]
      };
    }

    case UPDATE_ENUM_VALUE:
    case DELETE_ENUM_VALUE: {
      return {
        ...state,
        enums: _.unionBy([action.payload], state.enums, 'name')
      };
    }

    case DELETE_TABLE: {
      return {
        ...state,
        tables: state.tables.filter(t => t.name !== action.payload),
      };
    }

    case DELETE_ENUM: {
      return {
        ...state,
        enums: state.enums.filter(e => e.name !== action.payload)
      }
    }

    default:
      return state;
  }
};
