import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Popconfirm, Row } from 'antd';
import EditableTable from '../../components/table/EditableTable';
import { columnDefs } from "./constants/regulationsColumns";
import { useDispatch } from "react-redux";
import { fetchRegulations, sendNotification, updateRegulations } from "../../redux/actions";
import { v4 as uuid } from 'uuid';

const Regulations = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [saving, setSaving] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);
  const gridAPI = useRef(null);

  const onNew = () => {
    setData(data => [...data, { id: uuid(), label: '' }]);
  }

  const onSave = () => {
    let valid = true;
    const regulations = [];
    gridAPI.current.forEachNode(node => {
      const data = node.data;
      if (!data.label) {
        valid = false;
        return;
      }
      regulations.push(data);
    });
    if (!valid) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Label is required' }));
    setSaving(true);
    dispatch(updateRegulations(regulations))
      .finally(() => setSaving(false));
  }

  const onDelete = () => {
    const selectedIds = gridAPI.current.getSelectedRows().map(r => r.id);
    setData(data => data.filter(d => !selectedIds.includes(d.id)));
  }

  const fetchData = () => {
    gridAPI.current && gridAPI.current.showLoadingOverlay();
    gridAPI.current && gridAPI.current.paginationGoToFirstPage();
    dispatch(fetchRegulations())
      .then(({ payload }) => setData(payload))
      .finally(() => gridAPI.current && gridAPI.current.hideOverlay());
  }

  useEffect(() => {
    fetchData()
  }, [dispatch]);

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <Button type="primary" onClick={fetchData}>Refresh Table</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onNew}>Add Row</Button>
        </Col>
        <Col>
          <Button type="danger" onClick={onDelete} disabled={!selectedRows}>Delete Row</Button>
        </Col>
        <Col>
          <Popconfirm
            title="Are you sure to save this table?"
            onConfirm={onSave}
            okText="Yes"
            disabled={saving}
          >
            <Button type="primary" disabled={saving}>Save Table</Button>
          </Popconfirm>
        </Col>
      </Row>
      <EditableTable
        rowData={data}
        columnDefs={columnDefs}
        defaultColDefs={{
          editable: true,
          filter: true,
          floatingFilter: true
        }}
        setAPI={(api) => gridAPI.current = api}
        onSelectionChanged={() => setSelectedRows(!!gridAPI.current.getSelectedRows().length)}
      />
    </div>
  );
}

export default Regulations;
