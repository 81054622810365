import * as types from "../actions/types";

const thresholds = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_THRESHOLDS:
    case types.ADD_THRESHOLD:
    case types.DELETE_THRESHOLD: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { thresholds };
