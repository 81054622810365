import React, { Component } from "react";
import { Select, notification } from 'antd';
import axios from '../../../../util/Api';
import _ from 'lodash';

const Option = Select.Option;

export default class AGForeignCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.searchValues();
  }

  searchValues = (searchTerm, limit) => {
    this.setState({ loading: true });
    const { foreignTable, foreignColumn } = this.props;
    const token = localStorage.getItem('token');
    if (!limit) limit = 999999;
    const params = { foreignTable, foreignColumn, limit };
    if (searchTerm) params.searchTerm = searchTerm;
    axios.post('data/get_foreign_values', params, { headers: { authorization: 'Bearer ' + token } })
    .then((res) => {
      const { data, status } = res;
      if (status === 200) {
        this.setState({ options: data, loading: false });
      } else {
        this.setState({ options: [], loading: false });
        notification.error({ message: 'Cannot Fetch Foreign Keys!' });
      }
    });
  }

  throttledSearchValues = _.throttle(this.searchValues, 500, { leading: false });

  getValue() {
    const { value } = this.state;
    return value;
  }

  handleChange = (value) => {
    this.setState({ value });
  }

  render() {
    return (
      <Select
        showSearch
        style={{width: '100%'}}
        optionFilterProp="children"
        onChange={this.handleChange}
        size='small'
        defaultValue={this.state.value}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onSearch={(value) => this.throttledSearchValues(value)}
        loading={this.state.loading}
      >
        {this.state.options.map(o => <Option value={o.value} key={o.value}>{o.label}</Option> )}
      </Select>
    );
  }
}