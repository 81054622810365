import React, {Component} from "react";
import { DatePicker } from 'antd';
import moment from 'moment';

export default class AGDatepickerCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: null,
      date: null,
    }
  }

  componentWillMount() {
    const { value } = this.props;
    if (value) {
      const date = moment(value, 'YYYY-MM-DD');
      this.setState({ value, date });
    }
  }

  getValue() {
    const { value } = this.state;
    return value;
  }

  handleChange = (date, dateString) => {
    const momentDate = moment(dateString, "DD/MM/YYYY");
    this.setState({ value: momentDate.format('YYYY-MM-DD'), date });
  }

  render() {
    const { date } = this.state;
    return (
      <DatePicker onChange={this.handleChange} size={"small"} value={date} defaultPickerValue={date} format={"DD/MM/YYYY"} />
    );
  }
}