import React, { Component } from 'react';
import { Tag } from 'antd';
import _ from 'lodash';

export default class MultiSelectRenderer extends Component {
  state = {
    options: this.props.options,
    value: this.props.value,
  }

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value })
    }
    return true;
  }

  renderValues() {
    const { options, value } = this.state;
    if (_.isEmpty(value)) return this.props.placeholder;
    if (_.isEmpty(options)) return value;
    return value.map((currentValue) => {
      const selectedValue = options.find((option) => option.value === currentValue);
      if (!selectedValue) return <Tag key={currentValue}>{currentValue}</Tag>;
      const { value: valueFromSelectedValue, label } = selectedValue;
      return <Tag key={valueFromSelectedValue}>{label}</Tag>
    })
  }

  render() {
    return (
      <div>{this.renderValues()}</div>
    )
  }
}
