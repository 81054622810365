import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from 'antd';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import PieChart from "../components/charts/PieChart";
import XYChart from "../components/charts/XYChart";
import ArchiveTable from "../components/table/ArchiveTable";
import { fetchReports, fetchTableReport } from "../redux/actions";
import FiltersForm from './FiltersForm'

import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingId, setLoadingId] = useState(true);
  const [data, setData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState();

  const currentUser = useSelector((state) => state.auth, shallowEqual);
  const config = useSelector((state) => state.config, shallowEqual);
  const currentUserRegulations = currentUser.regulation.map((regulationId) => config.regulations.find((r) => r.id === regulationId));

  const fetchData = (values) => {
    setLoading(true);
    dispatch(fetchReports({ from: values[0].format("YYYY-MM-DD"), to: values[1].format("YYYY-MM-DD") }))
      .then(({ payload }) => setData(payload))
      .finally(() => setLoading(false))
  }

  const onFinish = ({ range }) => fetchData(range);

  const onViewData = async (id) => {
    setLoadingId(id);
    const result = await dispatch(fetchTableReport({ id, from: config.range[0].format("YYYY-MM-DD"), to: config.range[1].format("YYYY-MM-DD") }));
    setLoadingId(undefined);
    if (result.payload) {
      const { title, data } = result.payload;
      setModalData({ title, data });
      setModalVisible(true);
    }
  }

  const format = (data) => _.toPairs(data).map(([key, value]) => ({ category: key, value: value }));

  const renderCharts = (regulation) => {
    if (!regulation) return;
    const { id, label } = regulation;
    const regulationCharts = _.sortBy(data.filter((c) => c.regulation.includes(id)), 'chartposition');
    if (_.isEmpty(regulationCharts)) return null;
    const charts = regulationCharts.map((c) => {
      let size;
      switch (c.size) {
        case '1/4':
          size = 6;
          break;
        case '1/3':
          size = 8;
          break;
        case '1/2':
          size = 12;
          break;
        case '1':
          size = 24;
          break;
        default:
          size = 24;
      }
      const isNull = _.values(c.result).every(_.isEmpty);
      if (isNull) return null;
      if (c.type === 'pie') {
        return (
          <Col xs={24} sm={24} md={24} lg={size} key={c.id}>
            <PieChart
              title={c.label}
              data={format(c.result)}
              category="category"
              value="value"
              id={c.id}
              loadingId={loadingId}
              tablequery={c.tablequery}
              onViewData={onViewData}
            />
          </Col>
        );
      } else if (c.type === 'xy') {
        return (
          <Col xs={24} sm={24} md={24} lg={size} key={c.id}>
            <XYChart
              title={c.label}
              data={format(c.result)}
              category="category"
              value="value"
              id={c.id}
              loadingId={loadingId}
              tablequery={c.tablequery}
              onViewData={onViewData}
            />
          </Col>
        )
      }
      return null;
    });
    return (
      <Card size="small" title={label} headStyle={{ fontSize: 24, backgroundColor: "#f0f0f0", textAlign: "center", fontWeight: "700" }} bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }} key={id}><Row>{charts}</Row></Card>
    );
  }

  //  On initial load, fetch data based on initial state range
  useEffect(() => {
    fetchData(config.range)
  }, []);

  return (
    <div>
      <div className="finmatek-charts-form">
        <FiltersForm onFinish={onFinish} />
      </div>
      {!loading ? (
        <div style={{ marginTop: 32 }}>
          {currentUserRegulations.map(renderCharts)}
        </div>
      ) : null}
      <Modal
        title={modalData?.title || "Title"}
        visible={modalVisible}
        width={1200}
        onCancel={() => setModalVisible(false)}
        footer={<Button type="primary" onClick={() => setModalVisible(false)}>Close</Button>}
        bodyStyle={{ padding: 0 }}
      >
        <ArchiveTable
          rowData={modalData?.data || []}
          columnDefs={[]}
          defaultColDefs={{
            resizable: true,
            filter: true,
            floatingFilter: true
          }}
        />
      </Modal>
    </div>
  );
}
