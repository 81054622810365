import React, { Component } from "react";
import { Select } from 'antd';

const Option = Select.Option;

export default class AGEnumCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: null,
    }
  }

  componentWillMount() {
    const { value, options, data } = this.props;
    if (value) this.setState({ value });
    const enums = [];
    options.forEach((o) => {
      if (o.column && o.value) {
        if (data[o.column] === o.value) enums.push({ label: o.label, value: o.value });
      } else {
        enums.push({ label: o.label, value: o.value });
      }
    });
    this.setState({ enums })
  }

  getValue() {
    const { value } = this.state;
    return value;
  }

  handleChange = (value) => {
    this.setState({ value: value || null });
  }

  render() {
    return (
      <Select
        allowClear
        showSearch
        style={{width: '100%'}}
        optionFilterProp="children"
        onChange={this.handleChange}
        size='small'
        defaultValue={this.state.value}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {this.state.enums.map(e => <Option value={e.value} key={e.value}>{e.label}</Option> )}
      </Select>
    );
  }
}