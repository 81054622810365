import { nonRequiredStyle, requiredStyle } from '../../../others';

const getColumnDefs = ({ enumValues,  foreignTableValues, regulationOptions }) => [{
  headerName: 'Id',
  field: 'id',
  hide: true,
}, {
  headerName: 'Name',
  field: 'name',
  pinned: 'left',
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
}, {
  headerName: 'Label',
  field: 'label',
}, {
  headerName: 'Primary',
  field: 'primary',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: [false, true] }
}, {
  headerName: 'Type',
  field: 'type',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: ['text', 'number', 'decimal', 'isin'] },
  cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
}, {
  headerName: 'UI Type',
  field: 'uitype',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: ['text', 'number', 'date', 'select', 'foreignSelect'] },
}, {
  headerName: 'Enum',
  field: 'enum',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: enumValues },
}, {
  headerName: 'Required',
  field: 'required',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: [false, true] },
}, {
  headerName: 'Default Value',
  field: 'defaultValue',
}, {
  headerName: 'Regulation',
  field: 'regulation',
  cellRenderer: 'multiSelectRenderer',
  cellEditor: 'multiSelectEditor',
  cellRendererParams: {
    options: regulationOptions
  },
  cellEditorParams: {
    options: regulationOptions
  },
}, {
  headerName: 'Disabled',
  field: 'disabled',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: [false, true] },
}, {
  headerName: 'Hide',
  field: 'hide',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: [false, true] },
}, {
  headerName: 'Foreign Table',
  field: 'foreignTable',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: foreignTableValues }
}, {
  headerName: 'Foreign Column',
  field: 'foreignColumn',
}, {
  headerName: 'Exact Length',
  field: 'exactLength',
}, {
  headerName: 'Min Length',
  field: 'minLength',
}, {
  headerName: 'Max Length',
  field: 'maxLength',
}, {
  headerName: 'Width',
  field: 'width',
}, {
  headerName: 'Fix Column',
  field: 'fixColumn',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: ['left', 'right', ''] },
}, {
  headerName: 'Description',
  field: 'description',
  cellEditor: 'agLargeTextCellEditor',
  cellEditorParams: {
    maxLength: 20000,
    rows: 20,
    cols: 50,
  },
}, {
  headerName: 'Order',
  field: 'order',
  pinned: 'right',
  sort: 'asc',
  valueParser: params => Number(params.newValue)
}, {
  headerName: 'Threshold Enabled',
  field: 'thresholdEnabled',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: [false, true] },
}];

export { getColumnDefs };
