import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import _ from 'lodash';
import { Button } from 'antd';

// Themes begin
am4core.useTheme(am4themesAnimated);
// Themes end

class XYChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartDivName = `chart-${Math.random()}`;
  }

  componentDidMount() {
    const { category, data, value } = this.props;
    this.chart = am4core.create(this.chartDivName, am4charts.XYChart);
    this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    this.chart.exporting.menu = new am4core.ExportMenu();
    this.chart.data = data;
    /**
   * ========================================================
   * Enabling responsive features
   * ========================================================
   */

    this.chart.responsive.useDefault = false;

    this.chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 400) {
          return true;
        }
        return false;
      },
      state: function (target, stateId) {

        if (target instanceof am4charts.Chart) {
          const state = target.states.create(stateId);
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 0;
          return state;
        }

        if (target instanceof am4core.Scrollbar) {
          const state = target.states.create(stateId);
          state.properties.marginBottom = -10;
          return state;
        }

        if (target instanceof am4charts.Legend) {
          var state = target.states.create(stateId);
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 0;
          state.properties.marginLeft = 0;
          return state;
        }

        if (target instanceof am4charts.AxisRendererY) {
          const state = target.states.create(stateId);
          state.properties.inside = true;
          state.properties.maxLabelPosition = 0.99;
          return state;
        }

        if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererY)) {
          const state = target.states.create(stateId);
          state.properties.dy = -15;
          state.properties.paddingTop = 3;
          state.properties.paddingRight = 5;
          state.properties.paddingBottom = 3;
          state.properties.paddingLeft = 5;

          // Create a separate state for background
          target.setStateOnChildren = true;
          const bgstate = target.background.states.create(stateId);
          bgstate.properties.fill = am4core.color("#fff");
          bgstate.properties.fillOpacity = 0.7;

          return state;
        }

        // if ((target instanceof am4core.Rectangle) && (target.parent instanceof am4charts.AxisLabel) && (target.parent.parent instanceof am4charts.AxisRendererY)) { 
        //   var state = target.states.create(stateId);
        //   state.properties.fill = am4core.color("#f00");
        //   state.properties.fillOpacity = 0.5;
        //   return state;
        // }

        return null;
      }
    });

    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = category;
    categoryAxis.fontSize = 11;
    categoryAxis.renderer.minGridDistance = 20;
    

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.labels.template.disabled = true;

    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = category;
    series.dataFields.valueY = value;
    series.columns.template.tooltipText = '{valueY.value}';
    series.columns.template.tooltipY = 0;
    series.columns.template.strokeOpacity = 0;
    series.columns.template.showTooltipOn = "always";
    series.tooltip.pointerOrientation = "down";

    // as by default columns of the same series are of the same color,
    // we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add('fill', (fill, target) => this.chart.colors.getIndex(target.dataItem.index));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.data, prevProps.data)) {
      this.chart.data = this.props.data;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const {
      id,
      loadingId,
      onViewData,
      tablequery,
      title
    } = this.props;
    return (
      <div className="finmatek-chart-container">
        <h4>{title}</h4>
        <div id={this.chartDivName} className="finmatek-chart" />
        {tablequery && <Button size="small" style={{ marginBottom: 8 }} type="primary" loading={loadingId === id} onClick={() => onViewData(id)}>View Data</Button>}
      </div>
    );
  }
}

export default XYChart;
