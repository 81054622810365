import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addTableDataRow,
  deleteTableDataRow,
  fetchTableData,
  fetchTableRowData,
  fetchThresholds,
  sendNotification,
  updateTableDataRow
} from "../../redux/actions";
import EditableTable from "../../components/table/EditableTable";
import ThresholdsModal from "./ThresholdsModal";
import _ from 'lodash';
import validation from "./validation";
import { Button, Col, Modal, Row, Typography } from "antd";
import { v4 as uuid } from 'uuid';
import DescriptionModal from "./DescriptionModal";
import FiltersForm from "../FiltersForm";
import ArchiveTableModal from "../../components/modals/ArchiveTableModal";
import useWindowSize from "../../hooks/useWindowSize"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { tableName, regulation } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [columnDefs, setColumnDefs] = useState([])
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
  const gridAPI = useRef(null);
  const tableContainerRef = useRef();
  const [isThresholdsModalVisible, setIsThresholdsModalVisible] = useState(false);
  const [isArchiveTableModalVisible, setIsArchiveTableModalVisible] = useState(false);
  const tables = useSelector(state => state.config.tables, shallowEqual);
  const enums = useSelector(state => state.config.enums, shallowEqual);
  const auth = useSelector(state => state.auth, shallowEqual);
  const thresholds = useSelector(state => state.thresholds, shallowEqual);
  const selectedTable = useMemo(() => tables.find(t => t.name === tableName), [tables, tableName]);
  const primaryColumns = useMemo(() => selectedTable.columns.filter(c => c.primary).map(c => c.name), [selectedTable]);
  const validColumns = useMemo(() => selectedTable.columns.filter((c => c.regulation.includes(regulation))), [regulation, selectedTable]);
  const tableThresholds = useMemo(() => selectedTable.thresholdsEnabled ? thresholds.filter(t => t.table_name === tableName)  : [], [tableName, thresholds]);
  const config = useSelector((state) => state.config, shallowEqual);
  const { height } = useWindowSize();
  const y = tableContainerRef?.current?.offsetTop || 0;
  const tableHeight = height - y - 90;
  const onSave = async (row) => {
    const finalRow = _.mapValues(_.omit(row, ['_id', 'new', '_keys']), v => v === '' ? null : v);
    let valid = true;
    const columnsByName = _.keyBy(validColumns, c => c.name);
    const shownColumns = columnDefs.filter(c => !c.hide);
    for (const c of shownColumns) {
      if (!columnsByName[c.name]) continue;
      const validationResult = validation(columnsByName[c.name], finalRow);
      if (validationResult.error) {
        valid = false;
        dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: validationResult.description }));
        break;
      }
    }
    if (!valid) return;

    await dispatch(row.new ? addTableDataRow(tableName, finalRow) : updateTableDataRow(tableName, row._keys, finalRow));
    setData(data => data.map(r => {
      if (r._id === row._id) {
        delete r.new;
        r._keys = _.pick(row, primaryColumns);
      }
      return r;
    }));
  }

  const onViewData = async (row) => {
    await dispatch(fetchTableRowData(tableName, row))
      .then(({ payload }) => {
        setSelectedRowData({ data: payload, title: 'Selected Row Data' });
        setIsArchiveTableModalVisible(true);
      });
  }

  const onDelete = async (row) => {
    if (!row.new) {
      await dispatch(deleteTableDataRow(tableName, row._keys));
    }
    setData(data => data.filter(u => u._id !== row._id));
  }

  const onCopy = (row) => {
    let valid = true;
    gridAPI.current.forEachNode(node => node.data.new && (valid = false));
    if (!valid) {
      return dispatch(sendNotification({ type: 'error', message: 'Copy Row Error', description: 'Please save all new rows before adding a new one.' }));
    }
    const shownColumnsNames = validColumns.filter(c => !c.hide).map(c => c.name);
    const newRow = _.pick(row, shownColumnsNames);
    // primaryColumns.forEach(name => newRow[name] = null);
    newRow._id = uuid();
    newRow.new = true;
    setData(d => {
      const clonedData = _.cloneDeep(d)
      const existingRowIndex = clonedData.findIndex((r) => r._id === row._id)
      clonedData.splice(existingRowIndex + 1, 0, newRow);
      return clonedData;
    })
  }

  const checkIfThresholdIsValid = (threshold, value) => {
    const thresholdValue = _.toNumber(threshold.threshold)
    if (threshold.comparison === ">" && value > thresholdValue) return true;
    if (threshold.comparison === "<" && value < thresholdValue) return true;
    if (threshold.comparison === "=" && value === thresholdValue) return true;
    if (threshold.comparison === ">=" && value >= thresholdValue) return true;
    if (threshold.comparison === "<=" && value <= thresholdValue) return true;
    if (threshold.comparison === "<>" && value !== thresholdValue) return true;
    return false;
  }

  const getColumnDefs = (() => {
    const sortedColumns = _.orderBy(validColumns, (c) => _.parseInt(c.order) || Number.MAX_SAFE_INTEGER, "asc");
    const columns = sortedColumns.map((c, i) => {
      const columnDescription = c.description ? `<span class="ag-header-cell-text icon-info column-header-info-icon" data-columnname="${c.label || c.name}" data-description="${c.description}" role="columnheader" style="white-space: normal;position: absolute; right: 8px;"></span>` : ""; 
      const column = {
        headerName: `${c.label || c.name}${c.required ? ' *' : ''}`,
        field: c.name,
        id: c.id,
        name: c.name,
        hide: (auth.role !== 'admin') && ((c.hide === true) || (!c.primary && !_.intersection(auth.regulation, c.regulation).length)),
        pinned: c.fixColumn,
        width: c.width && parseInt(c.width),
        defaultValue: c.defaultValue,
        order: i,
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            columnDescription +
            '  </div>' +
            '</div>',
        },
        editable: !c.disabled,
        cellStyle: ({ data, value }) => {
          if (c.disabled) return { color: 'red', backgroundColor: '#cccccc' };
          const valid = validation(c, data);
          if (valid.error) {
            return { backgroundColor: '#ffa39e' };
          } else {
            //  Check if there is a threshold
            if (c.thresholdEnabled) {
              const threshold = tableThresholds.find((t) => t.column_name === c.name && checkIfThresholdIsValid(t, value))
              if (threshold) return { backgroundColor: threshold.color };
            }
            return { backgroundColor: 'transparent' };
          }
        }
      }
      column.cellRenderer = (params) => {
        const { data, value } = params;
        let cell = '';
        if (c.disabled) {
          cell = `<span class="icon icon-lock-screen" title="Column Disabled"></span> `;
        }
        const valid = validation(c, data);
        if (valid.error) {
          cell = `${cell}<span class="icon icon-error" title="${valid.description}"></span> `;
        }
        cell = `${cell} ${!_.isNil(value) ? value : ''}`;
        return cell;
      };
      switch (c.uitype) {
        case 'date':
          column.cellEditor = 'datepicker';
          column.cellRenderer = 'datepickerRenderer';
          break;
        case 'select':
          if (c.uitype === 'select' && c.enum) {
            const enumList = enums.find(e => e.name === c.enum);
            if (enumList) {
              const options = enumList.values;
              column.cellEditor = 'enumEditor';
              column.cellRenderer = 'enumRenderer';
              column.cellRendererParams = { options };
              column.cellEditorParams = { options };
            }
          }
          break;
        case 'foreignSelect':
          const foreignTable = c.foreignTable;
          const foreignColumn = c.foreignColumn;
          if (c.uitype === 'foreignSelect' && c.foreignTable && c.foreignColumn) {
            column.cellEditor = 'foreignEditor';
            column.cellEditorParams = { foreignTable, foreignColumn };
          }
          break;
        default:
      }
      return column;
    });
    if (selectedTable.rowQuery) {
      columns.push({
        headerName: 'Actions',
        field: 'actions',
        id: 'actions',
        cellRenderer: "viewDataActionRenderer",
        cellRendererParams: {
          onViewData,
          nodeId: '_id'
        },
        pinned: 'right',
        editable: false,
        width: 130,
      })
    }
    if (selectedTable.readonly) {
      return columns.map((c) => ({ ...c, editable: false }))
    } else {
      return [...columns, ...[
        {
          headerName: 'Saved',
          field: 'saved',
          hide: true,
          editable: false,
        },
        {
          headerName: 'Actions',
          field: 'actions',
          id: 'actions',
          cellRenderer: "actionsRenderer",
          cellRendererParams: {
            onSave,
            onDelete,
            onCopy,
            nodeId: '_id'
          },
          pinned: 'right',
          editable: false,
          width: 130,
        }
      ]];
    }
  });

  const fetchData = ({ range }) => {
    gridAPI.current && gridAPI.current.showLoadingOverlay();
    gridAPI.current && gridAPI.current.paginationGoToFirstPage();
    //  If selected table has search column, pass config range, otherwise pass empty array
    dispatch(fetchTableData(tableName, selectedTable.enableFiltering ? range : []))
      .then(({ payload }) => setData(payload.map(r => ({ ...r, _id: uuid(), new: false, _keys: _.pick(r, primaryColumns) }))))
      .finally(() => {
        gridAPI.current && gridAPI.current.hideOverlay();
      });
  }

  const fetchUserThresholds = () => {
    dispatch(fetchThresholds())
  }

  useEffect(() => {
    fetchData(config);
    fetchUserThresholds();
    const newColumnDefs = getColumnDefs();
    setColumnDefs(newColumnDefs);
  }, [gridAPI.current, tableName, primaryColumns])

  useEffect(() => {
    if (isThresholdsModalVisible === false && gridAPI.current) {
      gridAPI.current.refreshCells({ force: true });
    }
  }, [isThresholdsModalVisible]);

  useEffect(() => {
    setData([])
    setSelectedRowData({})
    const eventListener = (event) => {
      if(event.target?.classList?.value?.includes("column-header-info-icon")) {
        Modal.info({
          title: event.target.dataset.columnname,
          content: event.target.dataset.description,
          width: "75%",
          onOk() {},
        });
      }
    }
    document.addEventListener('click', eventListener)
    return () => document.removeEventListener('click', eventListener)
  }, [])

  const addNew = () => {
    let valid = true;
    gridAPI.current.forEachNode(node => node.data.new && (valid = false));
    if (!valid) {
      return dispatch(sendNotification({ type: 'error', message: 'New Row Error', description: 'Please save all new rows before adding a new one.' }));
    }
    const defaultValues = {};
    columnDefs.filter(c => c.defaultValue).map(c => defaultValues[c.name] = c.defaultValue);
    const newRowId = uuid();
    setData(data => [{ ...defaultValues, _id: newRowId, new: true, }, ...data]);
    gridAPI.current.ensureIndexVisible(0)
  }

  const exportCSV = () => {
    const columnKeys = columnDefs.filter(c => !['saved', 'actions'].includes(c.field) && !c.hide).map(c => c.field);
    gridAPI.current.exportDataAsCsv({ columnKeys });
  }

  const onViewThresholds = () => {
    setIsThresholdsModalVisible(true)
  }

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <h2 style={{ alignSelf: "flex-start" }}>{selectedTable.label}</h2>
        {selectedTable.enableFiltering && <FiltersForm onFinish={fetchData} />}
        <div />
      </div>
      <Row>
        <Col>
          {selectedTable.description &&
            (selectedTable.description.length < 200 ? (
              <p>{selectedTable.description}</p>
            ) : (
              <p>
                {selectedTable.description.substring(0, 200).concat("...")}
                <Typography.Link
                  underline
                  onClick={() => setIsDescriptionModalVisible(true)}
                  style={{ marginLeft: 2 }}
                >
                  show more
                </Typography.Link>
              </p>
            ))}
        </Col>
        <DescriptionModal
          title={`${selectedTable.label} description`}
          description={selectedTable.description}
          isModalVisible={isDescriptionModalVisible}
          handleOk={() => setIsDescriptionModalVisible(false)}
        />
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" onClick={fetchData}>Refresh Table</Button>
        </Col>
        {!selectedTable.readonly && (
        <Col>
          <Button type="primary" onClick={addNew}>Add New</Button>
        </Col>
        )}
        <Col>
          <Button type="primary" onClick={exportCSV}>Export CSV</Button>
        </Col>
        {selectedTable.thresholdsEnabled && (
        <Col>
          <Button type="primary" onClick={onViewThresholds}>View Thresholds</Button>
        </Col>
        )}
      </Row>
      <div ref={tableContainerRef}>
        <EditableTable
          rowData={data}
          columnDefs={columnDefs}
          getRowNodeId={(data) => data._id}
          setAPI={(api) => gridAPI.current = api}
          defaultColDefs={{
            editable: false,
            filter: true,
            floatingFilter: true,
            wrapText: true,
            autoHeight: true,
            sortable: true,
            resizable: true,
          }}
          height={tableHeight}
          autoHeightHeader={true}
        />
        {selectedTable.thresholdsEnabled && <ThresholdsModal tableName={tableName} isVisible={isThresholdsModalVisible} setIsVisible={setIsThresholdsModalVisible} />}
        {selectedTable.rowQuery && <ArchiveTableModal modalData={selectedRowData} modalVisible={isArchiveTableModalVisible} setModalVisible={setIsArchiveTableModalVisible} />}
      </div>
    </div>
  );
}
