import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import _ from "lodash";
import { Button } from 'antd';

// Themes begin
am4core.useTheme(am4themesAnimated);
// Themes end

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartDivName = `chart-${Math.random()}`;
  }

  componentDidMount() {
    const { category, data, value } = this.props;
    const chart = am4core.create(this.chartDivName, am4charts.PieChart);
    this.chart = chart;
    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = value;
    pieSeries.dataFields.category = category;

    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(30);
    chart.exporting.menu = new am4core.ExportMenu();

      // Add legend
  chart.legend = new am4charts.Legend();

  // Responsive
  chart.responsive.enabled = true;
  chart.responsive.rules.push({
    relevant: function (target) {
      if (target.pixelWidth <= 600) {
        return true;
      }
      return false;
    },
    state: function (target, stateId) {
      if (target instanceof am4charts.PieSeries) {
        var state = target.states.create(stateId);

        var labelState = target.labels.template.states.create(stateId);
        labelState.properties.disabled = true;

        var tickState = target.ticks.template.states.create(stateId);
        tickState.properties.disabled = true;
        return state;
      }

      return null;
    }
  });

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template
      // change the cursor on hover to make it apparent the object can be interacted with
      .cursorOverStyle = [
        {
          property: 'cursor',
          value: 'pointer',
        },
      ];

    pieSeries.alignLabels = true;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    const shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
    shadow.opacity = 0;

    // Create hover state
    const hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    const hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // Add a legend
    // chart.legend = new am4charts.Legend();
    chart.data = data || [];
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.data, prevProps.data)) {
      this.chart.data = this.props.data;
    }
  }

  render() {
    const {
      id,
      loadingId,
      onViewData,
      tablequery,
      title
    } = this.props;
    return (
      <div className="finmatek-chart-container">
        <h4>{title}</h4>
        <div id={this.chartDivName} className="finmatek-chart"/>\
        {tablequery && <Button size="small" style={{ marginBottom: 8 }} type="primary" loading={loadingId === id} onClick={() => onViewData(id)}>View Data</Button>}
      </div>
    );
  }
}

export default PieChart;
