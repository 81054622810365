import { useState } from "react";
import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import _ from "lodash";

import { login } from "../redux/actions";

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.auth, shallowEqual);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(login(values.username, values.password))
      .catch((err) => setError(err.error))
      .finally(() => setLoading(false));
  };

  if (!_.isNil(auth.token)) {
    return <Redirect to={_.get(location, "state.from", "/")} />;
  }

  return (
    <Row id="loginPageWrapper" type="flex" justify="center" align="middle" style={{ backgroundImage: "url('bg.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
      <Col>
        <Card id="login-card" bordered={false}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <img src="/finmatek.png" alt="finmatek logo" />
            <div id="loginText">Login</div>
          </div>
          {error ? (
            <Alert
              id="loginError"
              message={error}
              type="error"
              showIcon
              style={{ marginBottom: "10px" }}
            />
          ) : null}
          <Row>
            <Col>
              <Form
                onFinish={onFinish}
                className="login-form"
                layout="vertical"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Row>
                  <Col span={24}>
                    <Button
                      block
                      id="loginButton"
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={loading}
                    >
                      Log in
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col>
                    <span>
                      By logging in, you agree to our terms and conditions.
                    </span>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;