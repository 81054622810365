import * as types from "../actions/types";

const system = (
  state = { notification: null, redirect: null },
  action
) => {
  switch (action.type) {
    case types.SEND_NOTIFICATION: {
      return { ...state, notification: action.payload };
    }
    case types.REDIRECT: {
      return { ...state, redirect: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default { system };
