import * as types from "./types";

export const fetchThresholds = () => {
  return (api) => ({
    type: types.FETCH_THRESHOLDS,
    payload: api.get(`v1/thresholds`)
  });
}

export const addThreshold = (data) => {
  return (api) => ({
    type: types.ADD_THRESHOLD,
    payload: api.post(`v1/thresholds`, data)
  });
}

export const deleteThreshold = (data) => {
  return (api) => ({
    type: types.DELETE_THRESHOLD,
    payload: api.delete(`v1/thresholds`, data)
  });
}