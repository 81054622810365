import React, { Component } from 'react';
import _ from 'lodash';

class AGEnumRenderer extends Component {
  render() {
    const { value, options } = this.props;
    if (!value) return null;
    const option = options.find(o => o.value === value);
    if (!option) return value;
    return (
      <span>{option.label || value}</span>
    );
  }
}

export default AGEnumRenderer;
