import React, {Component} from 'react';
import _ from 'lodash';
import { Select } from 'antd';

const { Option } = Select;

export default class MultiSelectEditor extends Component {
  constructor(props) {
    super(props);
    this.item = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: props.value || [],
    }
  }

  renderOptions() {
    const options = this.props.options || [];
    return _.sortBy(options, (o) => o.label.toLowerCase()).map((option) => <Option key={option.value} value={option.value}>{option.label}</Option>)
  }

  getValue() {
    return this.state.value;
  }

  onChange(value) {
    this.setState({ value });
  }

  render() {
    const { value } = this.props;
    return (
      <Select
        allowClear
        showSearch
        optionFilterProp="children"
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Please select"
        defaultValue={value}
        onChange={this.onChange}
        size="small"
      >
        {this.renderOptions()}
      </Select>
    );
  }
}
