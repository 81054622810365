import React from 'react';
import { AllCommunityModules } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

//  Custom Renderers
import ActionsRenderer from './renderers/ActionsRenderer';
import BooleanRenderer from './renderers/BooleanRenderer';
import MultiSelectRenderer from './renderers/MultiSelectRenderer';
import SingleSelectRenderer from './renderers/SingleSelectRenderer';
import PasswordRenderer from './renderers/PasswordRenderer';
import AGDatepickerCellRenderer from './renderers/AGDatepickerCellRenderer';
import AGEnumRenderer from './renderers/AGEnumRenderer';
import ViewDataActionRenderer from './renderers/ViewDataActionRenderer';

//  Custom Editors
import BooleanEditor from './editors/BooleanEditor';
import MultiSelectEditor from './editors/MultiSelectEditor';
import NumericEditor from './editors/NumericEditor';
import SingleSelectEditor from './editors/SingleSelectEditor';
import DoubleEditor from './editors/DoubleEditor';
import AGDatepickerCellEditor from './editors/AGDatepickerCellEditor';
import AGEnumCellEditor from './editors/AGEnumCellEditor';
import AGForeignCellEditor from './editors/AGForeignCellEditor';

const EditableTable = (props) => {
  const {
    columnDefs,
    defaultColDefs,
    doesExternalFilterPass,
    getRowNodeId,
    headerHeight,
    height,
    isExternalFilterPresent,
    onCellValueChanged,
    onRowSelected,
    onSelectionChanged,
    rowData,
    rowHeight,
    rowSelection,
    setAPI
  } = props;

  function autoSizeAll(params, skipHeader) {
    const allColumnIds = params.columnApi.getAllColumns().map(column => column.getColId());
    setTimeout(() => params.columnApi.autoSizeColumns(allColumnIds, skipHeader), 10);
  }

  return (
    <div className="ag-theme-balham" style={{ flex: 1, paddingTop: 12, height: height || 600, paddingBottom: 24, }}>
      <AgGridReact
        applyColumnDefOrder={true}
        defaultColDef={{ suppressMovable: true, ...defaultColDefs }}
        columnDefs={columnDefs}
        rowData={rowData}
        modules={AllCommunityModules}
        multiSortKey={'ctrl'}
        headerHeight={headerHeight}
        rowSelection={rowSelection || 'multiple'}
        onRowSelected={onRowSelected}
        onSelectionChanged={onSelectionChanged}
        immutableData={true}
        animateRows={false}
        onCellValueChanged={onCellValueChanged}
        doesExternalFilterPass={doesExternalFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        rowDragManaged={true}
        rowHeight={rowHeight}
        suppressRowClickSelection={true}
        stopEditingWhenGridLosesFocus={false}
        getRowNodeId={(data) => getRowNodeId ? getRowNodeId(data) : data.id}
        pagination={false}
        frameworkComponents={{
          //  Renderers
          actionsRenderer: ActionsRenderer,
          booleanRenderer: BooleanRenderer,
          datepickerRenderer: AGDatepickerCellRenderer,
          enumRenderer: AGEnumRenderer,
          multiSelectRenderer: MultiSelectRenderer,
          passwordRenderer: PasswordRenderer,
          singleSelectRenderer: SingleSelectRenderer,
          viewDataActionRenderer: ViewDataActionRenderer,
          //  Editors
          booleanEditor: BooleanEditor,
          datepicker: AGDatepickerCellEditor,
          doubleEditor: DoubleEditor,
          enumEditor: AGEnumCellEditor,
          foreignEditor: AGForeignCellEditor,
          multiSelectEditor: MultiSelectEditor,
          numericEditor: NumericEditor,
          singleSelectEditor: SingleSelectEditor,
        }}
        gridOptions={{
          suppressPropertyNamesCheck: true
        }}
        onGridReady={(params) => {
          if (setAPI) setAPI(params.api);
        }}
      />
    </div>
  )
}

export default EditableTable;
