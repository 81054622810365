import { Button, message } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useState } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ onViewData, data, api, nodeId = "id" }) => {

  const [loading, setLoading] = useState(false)

  const _onViewData = async () => {
    const hide = message.loading('Loading..', 0);
    setLoading(true);
    try {
      await onViewData(api.getRowNode(data[nodeId]).data)
    } finally {
      hide();
      setLoading(false);
    }
  }

  return (
    <Button type="primary" size="small" onClick={_onViewData} disabled={loading} hidden={!onViewData}>
      <EyeOutlined/> View Data
    </Button>
  );
};
