import * as types from "./types";

export const updateUserFtp = (id, data) => {
  return (api) => ({
    type: types.UPDATE_USER,
    payload: api.put(`v1/user/ftp`, { id, data }),
    success: {
      message: "User Update",
      description: "User successfully updated",
    }
  });
}

export const validateForeignFTP = (username) => {
  return (api) => ({
    type: types.VALIDATE_FOREIGN_FTP,
    payload: api.post(`v1/user/validate-foreign-ftp`, { username }),
    success: {
      message: "Validate Foreign FTP",
      description: "Successfully validated Foreign FTP",
    }
  });
}