import * as types from "./types";

export const fetchReports = ({ from, to }) => {
  return (api) => ({
    type: types.FETCH_REPORTS,
    payload: api.get(`v1/reports?from=${from}&to=${to}`)
  });
}

export const fetchTableReport = ({ id, from, to }) => {
  return (api) => ({
    type: types.FETCH_TABLE_REPORT,
    payload: api.get(`v1/reports/table/${id}?from=${from}&to=${to}`)
  });
}