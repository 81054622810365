import { Button, message, Popconfirm } from 'antd';
import { CloudServerOutlined, CopyOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useState } from "react";

export default ({ onSave, onCopy, onDelete, onFtpCheck, data, api, nodeId = "id" }) => {

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [ftpChecking, setFtpChecking] = useState(false);

  const _onSave = async () => {
    const hide = message.loading('Saving..', 0);
    setSaving(true);
    try {
      await onSave(api.getRowNode(data[nodeId]).data)
    } finally {
      hide();
      setSaving(false);
    }
  }

  const _onDelete = async () => {
    const hide = message.loading('Deleting..', 0);
    setDeleting(true);
    try {
      await onDelete(api.getRowNode(data[nodeId]).data)
    } finally {
      hide()
      setDeleting(false);
    }
  }

  const _onFtpCheck = async () => {
    const hide = message.loading('Checking..', 0);
    setFtpChecking(true);
    try {
      await onFtpCheck(api.getRowNode(data[nodeId]).data)
    } finally {
      hide();
      setFtpChecking(false);
    }
  }

  const _onCopy = () => {
    onCopy(api.getRowNode(data[nodeId]).data)
  }

  return (
    <>
      <Button type="default" size="small" style={{ marginRight: 4 }} onClick={_onFtpCheck} disabled={ftpChecking} hidden={!onFtpCheck}>
        <CloudServerOutlined />
      </Button>
      <Button type={data.new ? "primary" : "default"} size="small" style={{ marginRight: 4 }} onClick={_onSave} disabled={saving} hidden={!onSave}>
        <SaveOutlined/>
      </Button>
      <Button type="default" size="small" style={{ marginRight: 4 }} onClick={_onCopy} hidden={!onCopy}>
        <CopyOutlined />
      </Button>
      {onDelete ? (
        <Popconfirm
          title="Are you sure to delete this row?"
          onConfirm={_onDelete}
          okText="Yes"
          disabled={deleting}
        >
          <Button type="default" size="small" style={{ marginRight: 4 }} disabled={deleting}>
            <DeleteOutlined/>
          </Button>
        </Popconfirm>
      ) : null}
    </>
  );
};
