import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select } from 'antd';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import EditableTable from '../../components/table/EditableTable';
import { getColumnDefs } from "./constants/enumsColumns";
import {
  addEnum,
  deleteEnum,
  deleteEnumValue,
  fetchEnums,
  sendNotification,
  updateEnumValue
} from "../../redux/actions";
import { useDispatch } from "react-redux";

const { Option } = Select;
const requiredRule = { required: true, message: "Required" };

const Enums = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [enums, setEnums] = useState([]);
  const [selectedEnum, setSelectedEnum] = useState();

  //  Add New Enum Form and Modal
  const [addNewEnumForm] = Form.useForm();
  const [newEnumModalVisibility, setNewEnumModalVisibility] = useState(false);

  //  Get Enums
  const getEnums = () => dispatch(fetchEnums()).then(({ payload }) => {
    setEnums(payload)
    return payload;
  });

  //  Select/Change Enum
  const changeSelectedEnum = async (enumName) => {
    const enums = await getEnums();
    const enumItem = enums.find((e) => e.name === enumName);
    if (enumItem) setData(enumItem.values);
    setSelectedEnum(enumName);
  }

  // Add New Enum
  const addNewEnum = async () => {
    try {
      await addNewEnumForm.validateFields();
      const { name } = addNewEnumForm.getFieldsValue();
      await dispatch(addEnum(name));
      await changeSelectedEnum(name);
      setNewEnumModalVisibility(false);
      addNewEnumForm.resetFields();
    } catch (err) {
      console.error(err.message);
    }
  }

  //  Delete Enum
  const onDeleteEnum = async () => {
    await dispatch(deleteEnum(selectedEnum)).then(() => changeSelectedEnum(null));
    await changeSelectedEnum();
  };

  //  Save Enum Value
  const onSaveValue = async (row) => {
    if (!row.name) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Name is required' }));
    if (!row.label) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Label is required' }));
    if (!row.value) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Value is required' }));
    await dispatch(updateEnumValue(selectedEnum, _.omit(row, "new")));
    setData(data.map((d) => {
      if (d.id === row.id) delete d.new;
      return d;
    }));
  }

  //  Delete Enum Value
  const onDeleteValue = async (row) => {
    if (!row.new) await dispatch(deleteEnumValue(selectedEnum, row.id));
    setData(data => data.filter(u => u.id !== row.id));
  }

  //  Table Column Defs
  const columnDefs = getColumnDefs({ onSave: onSaveValue, onDelete: onDeleteValue });

  useEffect(() => {
    getEnums();
  }, []);

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col>
          <span>Select Enum: </span>
          <Select style={{ minWidth: 200 }} onChange={changeSelectedEnum} value={selectedEnum}>{_.orderBy(enums, e => e.name, 'asc').map((enumItem) => <Option key={enumItem.name} value={enumItem.name}>{enumItem.name}</Option>)}</Select>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setNewEnumModalVisibility(true)}>Add New Enum</Button>
        </Col>
        <Col>
          <Popconfirm
            title="Are you sure to delete this enum?"
            onConfirm={onDeleteEnum}
            okText="Yes"
          >
            <Button type="danger">Delete Enum</Button>
          </Popconfirm>
        </Col>
      </Row>
      {selectedEnum && (
        <React.Fragment>
          <Row gutter={16}>
            <Col>
              <Button type="primary" onClick={() => setData([...data, { id: uuid(), new: true }])}>Add Value</Button>
            </Col>
          </Row>
          <EditableTable
            rowData={data}
            columnDefs={columnDefs}
            defaultColDefs={{ editable: true, filter: true, floatingFilter: true }}
          />
        </React.Fragment>
      )}
      <Modal
        title="Add New Enum"
        centered
        visible={newEnumModalVisibility}
        okText="Add Enum"
        onOk={addNewEnum}
        onCancel={() => setNewEnumModalVisibility(false)}
      >
        <Form form={addNewEnumForm} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Enum Name" name="name" rules={[requiredRule]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default Enums;
