import React, { useMemo, useState } from "react";
import { NavLink, Route, Switch, useLocation } from "react-router-dom";
import { AvatarDropdown, Notification, Redirect, RequiresAuth } from "../components";
import { Col, Layout, Menu, Row } from "antd";
import { CloudUploadOutlined, FolderOutlined, HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, TableOutlined } from "@ant-design/icons";
import { shallowEqual, useSelector } from "react-redux";
import _ from 'lodash';
import { AutoSubmissionPage, HomePage, Settings, TableDataPage } from "./index";

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;

export default () => (
  <Switch>
    <RequiresAuth path="/">
      <AuthenticatedMainPage />
    </RequiresAuth>
  </Switch>
)

const AuthenticatedMainPage = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const root = "/" + location.pathname.split("/")[1];
  const auth = useSelector(state => state.auth, shallowEqual);
  const config = useSelector(state => state.config, shallowEqual);

  const regulationsById = useMemo(() => _.mapValues(_.keyBy(config.regulations, 'id'), v => v.label), [config.regulations]);
  const menuItems = useMemo(() => [
    {
      path: "/",
      title: "Home",
      icon: <HomeOutlined />,
      component: HomePage,
    },
    ...(auth.role === 'admin' ? [{
      path: "/settings",
      title: "Settings",
      icon: <SettingOutlined />,
      component: <Settings />,
    }] : []),
    ...(auth.regulation.flatMap(rId => {
      const rLabel = regulationsById[rId];
      if (!rLabel) return [];
      const tables = _.orderBy(_.filter(config.tables, t => _.some(t.regulations, r => r === rId)), t => t.order, 'asc');
      if (!tables || !tables.length) return [];
      return [{
        path: `/tables/${rId}`,
        title: rLabel,
        icon: <FolderOutlined />,
        submenu: [
          ...(rLabel === 'MiFIR' ? [{
            path: "/auto-submission-form",
            title: "Auto Submission",
            icon: <CloudUploadOutlined />,
            component: <AutoSubmissionPage/>
          }]: []),
          ...tables.map(t => ({
            path: `/tables/${rId}/${t.name}`,
            title: t.label,
            icon: <TableOutlined />,
            component: <TableDataPage />
          }))
        ]
      }]
    }))
  ], [auth, regulationsById, config.tables]);

  const rootSubmenuKeys = menuItems.map(i => i.path)
  const initialOpenKey = _.get(_.maxBy(rootSubmenuKeys.map(key => ({ key, matchSize: _.includes(location.pathname, key) ? key.length : 0 })), 'matchSize'), 'key');
  const [openKeys, setOpenKeys] = useState([initialOpenKey]);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <React.Fragment>
      <Layout id="mainPageLayout">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <img src="/finmatek.png" alt="Finmatek Logo" />
          </div>
          <Menu
            id="topMenu"
            theme="dark"
            mode="inline"
            defaultOpenKeys={[root]}
            selectedKeys={[location.pathname]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            {menuItems.map(renderMenuItem)}
          </Menu>
        </Sider>
        <Layout>
          <Header id="topHeader">
            <Row>
              <Col span={18}>
                {collapsed ? (
                  <MenuUnfoldOutlined
                    className="headerMenu-IconBurger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                ) : (
                  <MenuFoldOutlined
                    className="headerMenu-IconBurger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}
              </Col>
              <Col span={6}>
                <div id="topHeaderRightElements" className="right">
                  <AvatarDropdown />
                </div>
              </Col>
            </Row>
          </Header>
          <Content id="mainPageContent">
            <Switch>
              <Route path="/tables/:regulation/:tableName">
                <TableDataPage />
              </Route>
              <Route path="/auto-submission-form">
                <AutoSubmissionPage />
              </Route>
              {auth.role === 'admin' ? (
                <Route path="/settings">
                  <Settings />
                </Route>
              ) : null}
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </Content>
          <Footer id="footer">Developed by <a href="https://leadeus.eu" target="_blank" rel="noreferrer">Leadeus Limited</a> | Version: {config.version}</Footer>
        </Layout>
      </Layout>
      <Notification />
      <Redirect />
    </React.Fragment>
  )
};

const Item = ({ to, children, exact = false, ...props }) => (
  <Menu.Item {...props}>
    <NavLink
      className="nav-text"
      activeClassName="active"
      to={to}
      exact={exact}
    >
      {children}
    </NavLink>
  </Menu.Item>
);

const renderMenuItemChildren = ({ icon, title }) => (
  <React.Fragment>
    {icon}
    <span>
      {title}
    </span>
  </React.Fragment>
);

const renderMenuItem = (item) => {
  return item.submenu ? (
    <SubMenu key={item.path} title={renderMenuItemChildren(item)}>
      {item.submenu.map(renderMenuItem)}
    </SubMenu>
  ) : (
    <Item key={item.path} to={item.path} exact={item.exact}>
      {item.children ? item.children : renderMenuItemChildren(item)}
    </Item>
  );
};
