import { Modal } from "antd";

const DescriptionModal = ({ description, isModalVisible, handleOk, title }) => {
  return (
    <Modal
      footer={null}
      onCancel={handleOk}
      title={title}
      visible={isModalVisible}
      width="50%"
    >
      <p style={{ whiteSpace: "pre-wrap" }}>{description}</p>
    </Modal>
  );
};

export default DescriptionModal;
