import _ from "lodash";
import * as types from "./types";

export const fetchTableData = (name, range) => {
  const queryParams = _.isEmpty(range) ? "" : `?from=${range[0].format("YYYY-MM-DD")}&to=${range[1].format("YYYY-MM-DD")}`;
  return (api) => ({
    type: types.FETCH_TABLE_DATA,
    payload: api.get(`v1/data/${name}${queryParams}`),
  });
}

export const fetchTableRowData = (name, { report_date, maker_symbol }) => {
  const queryParams = `?report_date=${report_date}&maker_symbol=${maker_symbol}`;
  return (api) => ({
    type: types.FETCH_TABLE_ROW_DATA,
    payload: api.get(`v1/data/${name}/row-data/${queryParams}`),
  });
}

export const addTableDataRow = (name, data) => {
  return (api) => ({
    type: types.ADD_TABLE_DATA_ROW,
    payload: api.post(`v1/data/row`, { name, data }),
    success: {
      message: "Add Row",
      description: "Successfully added row",
    }
  });
}

export const updateTableDataRow = (name, keys, data) => {
  return (api) => ({
    type: types.UPDATE_TABLE_DATA_ROW,
    payload: api.put(`v1/data/row`, { name, keys, data }),
    success: {
      message: "Update Row",
      description: "Successfully update row",
    }
  });
}

export const deleteTableDataRow = (name, keys) => {
  return (api) => ({
    type: types.DELETE_TABLE_DATA_ROW,
    payload: api.delete(`v1/data/row`, { name, keys }),
    success: {
      message: "Delete Row",
      description: "Successfully deleted row",
    }
  });
}

export const updateRange = (range) => {
  return () => ({
    type: types.UPDATE_RANGE,
    payload: range,
    notApi: true
  });
}
