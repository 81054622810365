import { Avatar, Button, Dropdown, Menu } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

import { logout } from "../../redux/actions";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth, shallowEqual);

  const onClickLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  const menu = () => (
    <Menu>
      <Menu.Item key="logout" onClick={onClickLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const renderAvatar = (user) => (
    <Avatar
      className="userAvatarDropdown"
      icon={<UserOutlined />}
      src={user.photo}
    >
      {user.name && user.name.charAt(0).toUpperCase()}
    </Avatar>
  );

  return (
    <Dropdown overlay={menu()}>
      <Button type="text" className="ant-dropdown-link userAvatarDropdownButton">
        {renderAvatar(user)}
        <span className="userAvatarDropdownText">{user.name}</span>
      </Button>
    </Dropdown>
  );
};
