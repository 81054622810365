import { nonRequiredStyle, requiredStyle } from '../../../others';

const getColumnDefs = ({ onSave, onDelete }) => [
  { headerName: 'Id', field: 'id', hide: true },
  {
    headerName: 'Name',
    field: 'name',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
  {
    headerName: 'Label',
    field: 'label',
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
  {
    headerName: 'Value',
    field: 'value',
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
  {
    headerName: "Actions",
    field: "actions",
    cellRenderer: "actionsRenderer",
    cellRendererParams: {
      onSave,
      onDelete,
    },
    editable: false,
    width: 120,
    pinned: "right",
  }
];

export { getColumnDefs };