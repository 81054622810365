import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Switch } from 'antd';
import { v4 as uuid } from 'uuid';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from 'lodash';

import { getColumnDefs } from './constants/tablesColumns';
import {
  addTable,
  changeTableDescription,
  changeTableLabel,
  changeTableOrder,
  changeTableReadOnly,
  changeTableRegulations,
  changeTableEnableFiltering,
  changeTableThresholdsEnabled,
  changeTableRowQuery,
  deleteTable,
  fetchTables,
  saveTable,
  sendNotification
} from "../../redux/actions";
import EditableTable from '../../components/table/EditableTable';

const { Option } = Select;
const requiredRule = { required: true, message: "Field is Required" };

const Tables = () => {
  const dispatch = useDispatch();
  const regulations = useSelector(state => state.config.regulations, shallowEqual);
  const enums = useSelector(state => state.config.enums, shallowEqual);
  const [data, setData] = useState([]);
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState();

  //  Table Changes
  const [addColumns, setAddColumns] = useState();
  const [removeColumns, setRemoveColumns] = useState();
  //  Add New Table
  const [addNewTableForm] = Form.useForm();
  const [newTableModalVisibility, setNewTableModalVisibility] = useState(false);
  // Update Table Options Modal
  const [tableOptionsForm] = Form.useForm();
  const [tableOptionsModalVisibility, setTableOptionsModalVisibility] = useState(false);
  //  Editable Table API
  const gridAPI = useRef(null);

  //  Get Tables
  const getTables = () => dispatch(fetchTables()).then(({ payload }) => {
    setTables(payload)
    return payload;
  });

  //  Select/Change Table
  const changeSelectedTable = async (tableName) => {
    tableOptionsForm.resetFields();
    const tables = await getTables();
    const table = tables.find((t) => t.name === tableName);
    if (table) {
      setData(table.columns.map(c => ({ ...c, new: false })));
      tableOptionsForm.setFieldsValue(table);
    }
    setSelectedTable(tableName);
    setAddColumns(null);
    setRemoveColumns(null);
  }

  // Add New Table
  const addNewTable = async () => {
    try {
      await addNewTableForm.validateFields();
      const { name, label, primaryName, primaryType } = addNewTableForm.getFieldsValue();
      await dispatch(addTable({
        name, label, columns: [{
          id: uuid(),
          name: primaryName,
          type: primaryType,
          primary: true,
          required: true,
          regulation: [],
          order: 1
        }]
      }));
      await changeSelectedTable(name);
      setNewTableModalVisibility(false);
      addNewTableForm.resetFields();
    } catch (err) {
      console.error(err.message);
    }
  }

  //  Delete Table Columns
  const onDeleteColumns = () => {
    const selectedRows = gridAPI.current.getSelectedRows();
    if (selectedRows.length) {
      const selectedRowIds = selectedRows.map(r => r.id);
      const selectedRowIdsWithoutNewRows = selectedRows.filter(r => !r.new).map(r => r.id);
      setAddColumns(addColumns => _.omit(addColumns, selectedRowIds));
      setRemoveColumns(removeColumns => _.union(removeColumns, selectedRowIdsWithoutNewRows));
      setData(data => data.filter(d => !selectedRowIds.includes(d.id)));
    }
  };

  //  Delete Table
  const onDeleteTable = async () => {
    await dispatch(deleteTable(selectedTable)).then(() => changeSelectedTable(null));
  };

  //  Save column changes in state
  const onCellValueChanged = ({ data }) => {
    data.primary = (data.primary === 'true') || (data.primary === true);
    data.required = (data.required === 'true') || (data.required === true);
    data.disabled = (data.disabled === 'true') || (data.disabled === true);
    data.hide = (data.hide === 'true') || (data.hide === true);
    const newChanges = { ...addColumns, [data.id]: data };
    setAddColumns(newChanges);
  }

  //  Save Table
  const onSaveTable = async () => {
    let valid = true;
    Object.values(addColumns || []).forEach(v => {
      if (!valid) return;
      if (!v.name || !v.type) valid = false;
      if (!v.name) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Name is required' }));
      if (!v.type) return dispatch(sendNotification({ type: 'error', message: 'Validation Error', description: 'Type is required' }));
    });
    if (!valid) return;
    if (!_.isEmpty(addColumns)) await dispatch(saveTable(selectedTable, _.mapValues(addColumns, v => _.omit(v, ['new'])), 'changes'));
    if (!_.isEmpty(removeColumns)) await dispatch(saveTable(selectedTable, removeColumns, 'removes'));
    await changeSelectedTable(selectedTable);
  }

  const onUpdateTableOptions = async () => {
    try {
      await tableOptionsForm.validateFields();
      const table = tables.find((t) => t.name === selectedTable);
      const {
        description,
        enableFiltering,
        label,
        order,
        readonly,
        regulations,
        rowQuery,
        thresholdsEnabled
      } = tableOptionsForm.getFieldsValue();
      if (label && label !== table.label) await dispatch(changeTableLabel(selectedTable, label));
      if (order && order !== table.order) await dispatch(changeTableOrder(selectedTable, order));
      if (description !== table.description) await dispatch(changeTableDescription(selectedTable, description));
      if (regulations && !_.isEqual(regulations, table.regulations)) await dispatch(changeTableRegulations(selectedTable, regulations));
      if (readonly !== table.readonly) await dispatch(changeTableReadOnly(selectedTable, readonly));
      if (thresholdsEnabled !== table.thresholdsEnabled) await dispatch(changeTableThresholdsEnabled(selectedTable, thresholdsEnabled));
      if (enableFiltering !== table.enableFiltering) await dispatch(changeTableEnableFiltering(selectedTable, enableFiltering));
      if (rowQuery !== table.rowQuery) await dispatch(changeTableRowQuery(selectedTable, rowQuery));
      getTables();
      setTableOptionsModalVisibility(false);
    } catch (err) {
      console.error(err.message);
    }
  }

  //  Get Tables on load
  useEffect(() => {
    getTables();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = getColumnDefs({
    enumValues: ['', ..._.orderBy(enums, 'name', 'asc').map(e => e.name)],
    foreignTableValues: ['', ..._.orderBy(tables, 'name', 'asc').filter(t => t.name !== selectedTable).map(t => t.name)],
    regulationOptions: regulations.map(r => ({ label: r.label, value: r.id }))
  })

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col>
          <span>Select Table: </span>
          <Select style={{ minWidth: 200 }} value={selectedTable} onChange={changeSelectedTable}>{_.orderBy(tables, t => t.name, 'asc').map((table) => <Option key={table.name} value={table.name}>{table.name}</Option>)}</Select>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setNewTableModalVisibility(true)}>Add New Table</Button>
        </Col>
        <Col>
          <Popconfirm
            title="Are you sure to delete this table?"
            onConfirm={onDeleteTable}
            okText="Yes"
          >
            <Button type="danger">Delete Table</Button>
          </Popconfirm>
        </Col>
      </Row>
      {selectedTable && (
        <React.Fragment>
          <Row gutter={16}>
            <Col>
              <Button type="primary" onClick={() => setData(data => [...data, { id: uuid(), new: true, ftp: {}, order: parseInt(_.get(_.orderBy(data, 'order', 'desc'), '0.order', 0)) + 1 }])}>Add New Column</Button>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure to delete this columns?"
                onConfirm={onDeleteColumns}
                okText="Yes"
              >
                <Button danger>Remove Columns</Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button onClick={() => setTableOptionsModalVisibility(true)}>Table Options</Button>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure to save this table?"
                onConfirm={onSaveTable}
                okText="Yes"
              >
                <Button type="primary">Save Table</Button>
              </Popconfirm>
            </Col>
          </Row>
          <EditableTable
            rowData={data}
            columnDefs={columnDefs}
            defaultColDefs={{ editable: true, filter: true, floatingFilter: true, resizable: true }}
            setAPI={(api) => gridAPI.current = api}
            onCellValueChanged={onCellValueChanged}
          />
        </React.Fragment>
      )}
      <Modal
        title="Add New Table"
        centered
        visible={newTableModalVisibility}
        okText="Add Table"
        onOk={addNewTable}
        onCancel={() => {
          addNewTableForm.resetFields();
          setNewTableModalVisibility(false);
        }}
      >
        <Form form={addNewTableForm} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Table Name" name="name" rules={[requiredRule]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Table Label" name="label" rules={[requiredRule]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Primary Column Name" name="primaryName" rules={[requiredRule]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Primary Column Type" name="primaryType" rules={[requiredRule]}>
                <Select>
                  <Option value="text">Text</Option>
                  <Option value="number">Number</Option>
                  <Option value="decimal">Decimal</Option>
                  <Option value="isin">Isin</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Update Table Options"
        centered
        visible={tableOptionsModalVisibility}
        okText="Update"
        onOk={onUpdateTableOptions}
        onCancel={() => setTableOptionsModalVisibility(false)}
        width="70%"
      >
        <Form form={tableOptionsForm} layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Form.Item label="Table Label" name="label">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item label="Table Order" name="order">
                <InputNumber style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item label="Read Only" name="readonly" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item label="Thresholds Enabled" name="thresholdsEnabled" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Form.Item label="Filtering Enabled" name="enableFiltering" valuePropName="checked" tooltip="Enable date filtering on tables. 'report_date' column is required!">
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Table Description" name="description">
                <Input.TextArea allowClear />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Row Query" name="rowQuery" tooltip={"$1 = {user_username} $2 = {report_date} $3 = {maker_symbol}"}>
                <Input.TextArea rows={1} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Regulations" name="regulations">
                <Select mode="multiple" allowClear style={{ width: "100%" }} placeholder="Select Regulations">
                  {regulations.map((r) => <Option key={r.id} value={r.id}>{r.label}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <table className="table table-ordering">
                <thead>
                  <tr>
                    <th>Table Label</th>
                    <th>Table Order</th>
                  </tr>
                </thead>
                <tbody>
                  {(selectedTable && tables.length) && _.sortBy(tables
                    .filter((t) => {
                      const table = tables.find((i) => i.name === selectedTable);
                      if (!table) return false;
                      const sameRegulations = _.intersection(t.regulations, table.regulations);
                      if (sameRegulations.length) return true;
                      return false;
                    }), "order")
                    .map((t) => <tr key={t.name}><td>{t.label}</td><td>{t.order}</td></tr>)
                  }
                </tbody>
              </table>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default Tables;
