import React, { Component } from 'react';

export default class BooleanRenderer extends Component {
  state = {
    value: this.props.value,
  }

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value })
    }
    return true;
  }

  renderCell = () => {
    const { value } = this.state;
    if (!value) return <span>No</span>;
    return <span>Yes</span>
  }

  render() {
    return (
      this.renderCell()
    )
  }
}