import React, { Component } from 'react';
import { Tag } from 'antd';
import _ from 'lodash';

export default class ArrayOfObjectsRenderer extends Component {
  state = {
    value: this.props.value,
  }

  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({ value: params.value })
    }
    return true;
  }

  renderValues() {
    const { value } = this.state;
    if (_.isEmpty(value)) return this.props.placeholder;
    return value.map((currentValue) => {
      return <Tag key={currentValue[this.props.fieldKey]}>{currentValue[this.props.fieldKey]}</Tag>
    })
  }

  render() {
    return (
      <div>{this.renderValues()}</div>
    )
  }
}