import { Col, Row, Tabs } from 'antd';
import { SettingOutlined, TableOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';

import Users from './Users';
import Enums from './Enums';
import Tables from './Tables';
import Regulations from "./Regulations";
import Charts from "./Charts";
import CompetentAuthorities from "./CompetentAuthorities";

const { TabPane } = Tabs;
const Settings = () => {
  return (
    <Tabs defaultActiveKey="tables" centered>
      <TabPane tab={<span><TableOutlined />Tables</span>} key="tables">
        <Tables />
      </TabPane>
      <TabPane tab={<span><UnorderedListOutlined />Enums</span>} key="enums">
        <Enums />
      </TabPane>
      <TabPane tab={<span><UserOutlined />Users</span>} key="users">
        <Users />
      </TabPane>
      <TabPane tab={<span><SettingOutlined />Other</span>} key="regulations">
        <Row>
          <Col span={24}>
            <h2>Regulations</h2>
            <Regulations />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h2>Charts</h2>
            <Charts />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h2>Competent Authorities</h2>
            <CompetentAuthorities />
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  )
}

export default Settings;
