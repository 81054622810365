import { nonRequiredStyle, requiredStyle } from '../../../others';

const columnDefs = [
  {
    headerName: "Name",
    field: "name",
    editable: true,
    checkboxSelection: true,
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
  {
    headerName: "Host",
    field: "host",
    editable: true,
  },
  {
    headerName: "In Folder",
    field: "inFolder",
    editable: true,
  },
  {
    headerName: "Out Folder",
    field: "outFolder",
    editable: true,
  },
];

export { columnDefs };
