import validator from 'validator';
import _ from 'lodash';

const validate = (column, data) => {
  const name = column.label || column.name;
  if ((column.primary || column.required) && (_.isNil(data[column.name]) || data[column.name] === '')) {
    return {
      error: true,
      description: `${name} is required`,
    };
  }
  //  Check for exact Length
  if ((column.exactLength || column.minLength || column.maxLength)
    && (column.required || data[column.name])) {
    let min;
    let max;
    let errorMsg = '';
    if (column.exactLength) {
      min = column.exactLength;
      max = column.exactLength;
      errorMsg = `Length of ${name} must be equal to ${column.exactLength}`;
    } else {
      if (column.minLength) min = column.minLength;
      if (column.maxLength) max = column.maxLength;
      errorMsg = `Length of ${name} must be`;
      if (min !== undefined && max !== undefined) {
        errorMsg = `${errorMsg} min ${column.minLength} and max ${column.maxLength}`;
      } else if (min !== undefined) {
        errorMsg = `${errorMsg} min ${column.minLength}`;
      } else if (max !== undefined) {
        errorMsg = `${errorMsg} max ${column.maxLength}`;
      }
    }
    const valid = validator.isByteLength(data[column.name], { min, max });
    if (!valid) {
      return {
        error: true,
        description: errorMsg,
      };
    }
  }
  let valid = true;
  if (data[column.name]) {
    switch (column.type) {
      case 'isin':
        valid = validator.isISIN(data[column.name].toString());
        if (!valid) {
          return {
            error: true,
            description: `${name} must be a valid ISIN (stock/security identifier)`,
          };
        }
        break;
      case 'decimal':
        valid = validator.isDecimal(data[column.name].toString());
        if (!valid) {
          return {
            error: true,
            description: `${name} must be a valid Decimal Number`,
          };
        }
        break;
      case 'number':
        valid = validator.isInt(data[column.name].toString());
        if (!valid) {
          return {
            error: true,
            description: `${name} must be a valid Integer`,
          };
        }
        break;
      default:
    }
  }
  return { error: false };
};

export default validate;
