import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAuthorities, fetchMe, updateUserFtp, validateForeignFTP } from "../redux/actions";

import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import _ from "lodash";

const { Option } = Select;

const AutoSubmissionPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [checkingFtp, setCheckingFtp] = useState(false);
  const [saving, setSaving] = useState(false);
  const authorities = useSelector(state => _.uniqBy([...state.config.authorities, { name: 'Other', host: '', inFolder: '', outFolder: '' }], 'name'), shallowEqual);

  //  Get Current User from state
  const currentUser = useSelector((state) => state.auth, shallowEqual);

  const onAuthorityChange = (value) => {
    const selected = authorities.find(authority => authority.name === value);
    form.setFieldsValue({
      foreignHost: selected.host || '',
      foreignInFolder: selected.inFolder || '',
      foreignOutFolder: selected.outFolder || '',
    });
  }

  const onCheckFtp = async () => {
    setCheckingFtp(true);
    try {
      await dispatch(validateForeignFTP(currentUser.username));
    } catch (err) {
      console.error(err);
    } finally {
      setCheckingFtp(false);
    }
  }

  const onSave = async () => {
    try {
      await form.validateFields();
      setSaving(true);
      const values = form.getFieldsValue();
      const data = { ftp: values };
      await dispatch(updateUserFtp(currentUser.id, data));
      setSaving(false);
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
  }

  const onEnabledChange = async (enabled) => {
    try {
      await dispatch(updateUserFtp(currentUser.id, { ftp: { enabled } }));
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    dispatch(fetchAuthorities());
    dispatch(fetchMe());
  }, []);

  useEffect(() => {
    const userAuthority = _.get(currentUser, "ftp.competentAuthority");
    form.setFieldsValue({
      ...currentUser.ftp,
      competentAuthority:  userAuthority || 'Other'
    });
  }, []);

  return (
    <Row gutter={16}>
      <Col offset={6} span={12}>
        <div style={{ textAlign: 'center' }}>
          <h1>MiFIR Auto Submission</h1>
          <h4>Please fill your MiFIR Auto Submission FTP Settings below</h4>
        </div>

        <Form form={form} layout="vertical" onFinish={onSave}>
          <div style={{ textAlign: "center", marginBottom: 8 }}>
            <span>Enabled: </span>
            <Form.Item label="" name="enabled" valuePropName="checked" noStyle>
              <Switch onChange={onEnabledChange} />
            </Form.Item>
            <Form.Item label="" style={{ textAlign: "center" }}>
              <Button type="primary" size="small" ghost onClick={() => onCheckFtp()} loading={checkingFtp} disabled={saving}>{checkingFtp ? "Checking Settings" : "Check Settings"}</Button>
            </Form.Item>
          </div>
          <Form.Item label="Competent Authority" name="competentAuthority">
            <Select onChange={onAuthorityChange}>
              {authorities.map(authority => (
                <Option key={authority.name} value={authority.name}>{authority.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Host" name="foreignHost">
            <Input autoComplete="false" onChange={(e) => {
              const selected = authorities.find(a => a.host && (a.host === e.target.value));
              if (selected) {
                form.setFieldsValue({ competentAuthority: selected.name });
              }
            }}/>
          </Form.Item>
          <Form.Item label="Username" name="foreignUsername">
            <Input autoComplete="false" />
          </Form.Item>
          <Form.Item label="Password" name="foreignPassword">
            <Input.Password autoComplete="false" placeholder="Change Password" />
          </Form.Item>
          <Form.Item label="In Folder" name="foreignInFolder">
            <Input autoComplete="false" />
          </Form.Item>
          <Form.Item label="Out Folder" name="foreignOutFolder">
            <Input autoComplete="false" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginRight: 8 }} loading={saving} disabled={checkingFtp}>
              {saving ? "Saving..." : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default AutoSubmissionPage;
