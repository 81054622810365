import { nonRequiredStyle, requiredStyle } from '../../../others';

const getColumnDefs = ({ regulationOptions, onSave, onDelete, onFtpCheck }) => [
  {
    field: "id",
    headerName: "Id",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    pinned: "left",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
  {
    headerName: "Username",
    field: "username",
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
  },
  {
    headerName: "Password",
    field: "password",
    cellRenderer: "passwordRenderer",
    width: 140,
    cellClass: (params) => {
      if (params.data.new && !params.value) return "fieldIsRequiredClass";
      return null;
    }
  },
  {
    headerName: "Emails",
    field: "emails"
  },
  {
    headerName: "Regulation",
    field: "regulation",
    cellRenderer: "multiSelectRenderer",
    cellEditor: "multiSelectEditor",
    cellRendererParams: {
      options: regulationOptions
    },
    cellEditorParams: {
      options: regulationOptions
    },
    width: 120,
  },
  {
    headerName: "Photo URL",
    field: "photo",
    width: 120
  },
  {
    headerName: "Local FTP Host",
    field: "ftp.localHost"
  },
  {
    headerName: "Local FTP Username",
    field: "ftp.localUsername"
  },
  {
    headerName: "Local FTP Password",
    field: "ftp.localPassword",
    cellRenderer: "passwordRenderer",
  },
  {
    headerName: "Local FTP In Folder",
    field: "ftp.localInFolder"
  },
  {
    headerName: "Local FTP Out Folder",
    field: "ftp.localOutFolder"
  },
  {
    headerName: "Foreign FTP Host",
    field: "ftp.foreignHost"
  },
  {
    headerName: "Foreign FTP Username",
    field: "ftp.foreignUsername"
  },
  {
    headerName: "Foreign FTP Password",
    field: "ftp.foreignPassword",
    cellRenderer: "passwordRenderer",
  },
  {
    headerName: "Foreign FTP In Folder",
    field: "ftp.foreignInFolder"
  },
  {
    headerName: "Foreign FTP Out Folder",
    field: "ftp.foreignOutFolder"
  },
  {
    headerName: "FTP Enabled",
    field: "ftp.enabled",
    cellRenderer: "booleanRenderer",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [true, false]
    },
  },
  {
    headerName: "Actions",
    field: "actions",
    cellRenderer: "actionsRenderer",
    cellRendererParams: {
      onSave,
      onDelete,
      onFtpCheck
    },
    editable: false,
    width: 120,
    pinned: "right",
  }
];

export { getColumnDefs };
