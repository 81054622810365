import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Alert, Button, Col, Form, InputNumber, Modal, Row, Select, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { addThreshold, deleteThreshold } from '../../redux/actions';

const { Option } = Select;

const colors = [
    { name: "Green", code: "#95de64" },
    { name: "Lime", code: "#bae637" },
    { name: "Red", code: "#ff4d4f" },
    { name: "Blue", code: "#91d5ff" },
    { name: "Yellow", code: "#fffb8f" },
    { name: "Orange", code: "#ffa940" },
    { name: "Volcano", code: "#ff7a45" },
    { name: "Purple", code: "#9254de" },
];

const ThresholdsModal = ({ isVisible, setIsVisible, tableName }) => {
    const dispatch = useDispatch();
    //  Redux State
    const tables = useSelector(state => state.config.tables, shallowEqual);
    const thresholds = useSelector(state => state.thresholds, shallowEqual);
    //  Local State
    const [data, setData] = useState([]);
    const [tableThresholds, setTableThresholds] = useState([]);
    const [addingThreshold, setAddingThreshold] = useState(false);
    const [deletingThreshold, setDeletingThreshold] = useState(false);
    const selectedTable = tables.find(t => t.name === tableName);

    const [form] = Form.useForm();
    const requiredRule = { required: true };

    const onDeleteThreshold = async (threshold) => {
        setDeletingThreshold(threshold);
        await dispatch(deleteThreshold({ table_name: tableName, ...threshold }));
        setDeletingThreshold(false);
    }

    const onAddThreshold = async (threshold) => {
        try {
            setAddingThreshold(true);
            await dispatch(addThreshold({ table_name: tableName, ...threshold }));
            await form.resetFields();
            setAddingThreshold(false);
        } catch(err) {
            setAddingThreshold(false);
        }
    }

    const onModalClose = async () => {
        await form.resetFields();
        setIsVisible(false);
    }

    const columns = [
        {
            title: 'Column Name',
            dataIndex: 'column_name',
            key: 'column_name',
            render: (text) => {
                const column = selectedTable.columns.find((c) => c.name === text);
                return column.label;
            }
        },
        {
            title: 'Comparison',
            dataIndex: 'comparison',
            key: 'comparison',
        },
        {
            title: 'Threshold Value',
            dataIndex: 'threshold',
            key: 'threshold',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            render: (backgroundColor) => <div style={{ width: 16, height: 16, backgroundColor }} />
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Button type="text" icon={<DeleteOutlined />} onClick={() => onDeleteThreshold(record)} loading={_.isEqual(deletingThreshold, record)} />
            }
        },
    ]

    useEffect(() => {
        const tableThresholds = thresholds.filter(t => t.table_name === tableName);
        setData(tableThresholds);
        setTableThresholds(selectedTable.columns.filter((c) => c.thresholdEnabled))
    }, [selectedTable.columns, tableName, thresholds]);

    return (
        <Modal
            title={`${selectedTable.label} Table Thresholds`}
            visible={isVisible}
            width="50%"
            onCancel={onModalClose}
            footer={
                <Button type="primary" onClick={onModalClose}>Close</Button>
            }>
            <div style={{ background: "#eeeeee", padding: 16, marginBottom: 24 }}>
                <h3>Add new Threshold</h3>
                {tableThresholds.length ? (
                <Form form={form} name="form-new-threshold" layout="vertical" onFinish={onAddThreshold}>
                    <Row gutter="8">
                        <Col>
                            <Form.Item name="column_name" label="Column Name" rules={[ requiredRule ]}>
                                <Select allowClear showSearch style= {{ width: 240 }}>
                                    {tableThresholds.map((c) => <Option key={c.name} value={c.name}>{c.label}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="comparison" label="Comparison" rules={[ requiredRule ]}>
                                <Select style={{ width: 120 }}>
                                    <Option value=">">{">"}</Option>
                                    <Option value="<">{"<"}</Option>
                                    <Option value="=">{"="}</Option>
                                    <Option value=">=">{">="}</Option>
                                    <Option value="<=">{"<="}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="threshold" label="Threshold Value" rules={[ requiredRule ]}>
                                <InputNumber style={{ width: 120 }} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="color" label="Color" rules={[ requiredRule ]}>
                                <Select style={{ width: 140 }}>
                                    {colors.map(({ name, code }) => 
                                        <Option key={code} value={code}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ width: 16, height: 16, backgroundColor: code, marginRight: 8 }} />
                                                <span>{name}</span>
                                            </div>
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label=" ">
                                <Button type="primary" htmlType="submit" loading={addingThreshold}>
                                    {addingThreshold ? <span>Adding</span> : <span>Add Threshold</span>}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                ) : <Alert message="No more empty threshold columns available!" type="info" showIcon />}
            </div>
            <h3>Thresholds</h3>
            <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={({ table_name, column_name, comparison, threshold, color }) => `${table_name}-${column_name}-${comparison}-${threshold}-${color}`}
                size="small"
            />
        </Modal>
    )
}

export default ThresholdsModal;