//  Auth Actions
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const SET_TOKEN = "SET_TOKEN";
export const FETCH_ME = "FETCH_ME";
export const RESET = "RESET";
export const LOGOUT = "LOGOUT";
export const FETCH_CONFIG = "FETCH_CONFIG";

//  System Actions
export const REDIRECT = "REDIRECT";
export const SEND_NOTIFICATION = "SEND-SEND_NOTIFICATION";

//  ADMIN Users Actions
export const FETCH_USERS = "FETCH_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

//  ADMIN Regulations Actions
export const FETCH_REGULATIONS = "FETCH_REGULATIONS";
export const UPDATE_REGULATIONS = "UPDATE_REGULATIONS";

// ADMIN Authorities Actions
export const FETCH_AUTHORITIES = "FETCH_AUTHORITIES";
export const UPDATE_AUTHORITIES = "UPDATE_AUTHORITIES";

//  ADMIN Chart Actions
export const FETCH_CHARTS = "FETCH_CHARTS";
export const UPDATE_CHARTS = "UPDATE_CHARTS";

//  ADMIN Table Actions
export const FETCH_TABLES = "FETCH_TABLES";
export const ADD_TABLE = "ADD_TABLE";
export const DELETE_TABLE = "DELETE_TABLE";
export const SAVE_TABLE = "SAVE_TABLE";
export const UPDATE_TABLE = "UPDATE_TABLE";

//  ADMIN Enum Actions
export const FETCH_ENUMS = "FETCH_ENUMS";
export const ADD_ENUM = "ADD_ENUM";
export const DELETE_ENUM = "DELETE_ENUM";
export const UPDATE_ENUM_VALUE = "UPDATE_ENUM_VALUE";
export const DELETE_ENUM_VALUE = "DELETE_ENUM_VALUE";

//  Table Actions
export const FETCH_TABLE_DATA = "FETCH_TABLE_DATA";
export const FETCH_TABLE_ROW_DATA = "FETCH_TABLE_ROW_DATA";
export const ADD_TABLE_DATA_ROW = "ADD_TABLE_DATA_ROW";
export const UPDATE_TABLE_DATA_ROW = "UPDATE_TABLE_DATA_ROW";
export const DELETE_TABLE_DATA_ROW = "DELETE_TABLE_DATA_ROW";

//  FTP Actions
export const VALIDATE_FTP = "VALIDATE_FTP";
export const VALIDATE_FOREIGN_FTP = "VALIDATE_FOREIGN_FTP";

export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_TABLE_REPORT = "FETCH_TABLE_REPORT";

//  THRESHOLDS
export const FETCH_THRESHOLDS = "FETCH_THRESHOLDS";
export const ADD_THRESHOLD = "ADD_THRESHOLD";
export const DELETE_THRESHOLD = "DELETE_THRESHOLD";

//  RANGE
export const UPDATE_RANGE = "UPDATE_RANGE"