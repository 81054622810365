import { nonRequiredStyle, requiredStyle } from '../../../others';

const getColumnDefs = ({ regulationOptions }) => [
  {
    headerName: "Label",
    field: "label",
    editable: true,
    checkboxSelection: true,
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
    resizable: true,
  },
  {
    headerName: "Regulation",
    field: "regulation",
    cellRenderer: "multiSelectRenderer",
    cellEditor: "multiSelectEditor",
    cellRendererParams: {
      options: regulationOptions
    },
    cellEditorParams: {
      options: regulationOptions
    },
    width: 120,
    resizable: true,
  },
  {
    headerName: 'Type',
    field: 'type',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['pie', 'xy']
    },
    refData: {
      'pie': 'Pie',
      'xy': 'XY'
    },
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
    resizable: true,
  },
  {
    headerName: "Query",
    field: "query",
    editable: true,
    cellStyle: (params) => !params.value ? requiredStyle : nonRequiredStyle,
    resizable: true,
  },
  {
    headerName: 'Size',
    field: 'size',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['1/4', '1/3', '1/2', '1']
    },
    editable: true,
    resizable: true,
  },
  {
    headerName: 'Position',
    field: 'chartposition',
    editable: true,
    sort: "asc",
    resizable: true,
  },
  {
    headerName: "Table Query",
    field: "tablequery",
    editable: true,
    resizable: true,
  },
  {
    headerName: "Table Name",
    field: "tablename",
    editable: true,
    resizable: true,
  },
];

export { getColumnDefs };
